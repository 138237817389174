export default {
  name: "DetailTHistoryProfitShare",

  data() {
    return {
      data: {},
      property: {
        animation: {
          submitLoading: false,
        },
      },
      form: {
        title: "",
        isTransfered: false,
        documentProofNumber: "",
        note: "",
        profitShareHistoryId: "",
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      var params = this.$route.params.id;
      if (!params) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageList();
        return;
      }
      this.data = JSON.parse(this.decryptBASE64(params));
      this.form.isTransfered = this.data.isTransfered;
      console.log(this.data);
    },
    onChangeStatus() {
      this.form.note = "";
      this.form.documentProofNumber = "";
    },

    routeToPageList() {
      this.$router.push("/history-profit-share");
    },
    async editHistoryProfitShare() {
      const data = {
        isTransfered: this.form.isTransfered,
        documentProofNumber: this.form.documentProofNumber,
        note: this.form.note,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "history-profit-share/" + this.data.profitShareHistoryId,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          this.routeToPageList();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.property.animation.submitLoading = false;
      }
    },
  },
  mounted() {
    this.getIdentityFromSession();
  },
};
